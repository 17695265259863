import {useState, useMemo} from 'react'
import {withGlobal} from './app'
import {BtnSpin} from '../misc/buttons'
import {DebugBlock} from '../misc/debug'
import {asArray, failValidation} from '../../lib/utils'
import GDPR from '../widgets/gdpr'
import TOS from '../widgets/tos'
import AddressForm from '../misc/address-form'

const Login = ({
  t,
  api,
  query,
  invitedBy,
  Auth,
  toaster,
  page,
  Profile,
  id,
  env,
  className,
  needSignup,
  errorsScope = 'errors-scope',
  team = false
}) => {
  const [identifier, setIdentifier] = useState(query.id || id || '')
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [relationship, setRelationship] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [teamMode, setTeamMode] = useState(team)
  const [action, setAction] = useState(query.action || 'start')
  const [loginLink, setLoginLink] = useState('')
  const [address, setAddress] = useState({})

  const login = async () => {
    if (failValidation('identifier', t, {selector: `.${errorsScope}`})) return
    if (
      action === 'signup' &&
      failValidation('name,relationship', t, {selector: `.${errorsScope}`})
    )
      return
    try {
      setLoading(true)
      const method = teamMode ? 'get' : 'post'
      const route = teamMode ? '/auth/login' : `/pages/${page.slug}/auth`
      const signup = action == 'signup'
      const body = {
        identifier,
        password,
        signup,
        relationship,
        name,
        location: address,
        nextFlow: 'tab-infos'
      }

      const {err, errors, jwt, nextAction, debugLoginLink} = await api[method](route, {body})
      if (err == 'profile:invalid') throw 'oups ! invalid email'
      else if (err == 'unauthorized') throw 'Accès non autorisé.'
      else if (err) throw 'oups ! error not handled'
      if (errors) throw t('login.err')

      if (nextAction == 'login' && jwt) {
        if (team) return Auth.login(jwt, {reload: true})
        return Auth.login(jwt, {redirect: `/${page.slug}/tab-infos`})
      }

      if (nextAction === 'signup' && needSignup) needSignup()

      nextAction && setAction(nextAction)
      debugLoginLink && setLoginLink(debugLoginLink)

      setLoading(false)
    } catch (e) {
      toaster.error(e)
      console.log('login.err', e)
      setLoading(false)
    }
  }

  const submit = async (e) => {
    e.preventDefault()
    login()
  }

  const defunctGender = useMemo(() => {
    const {gender} = page

    const asGender = !!gender && ['m', 'f'].includes(gender)

    if (!asGender) return 'm'

    return gender
  }, [])

  return (
    <div
      className={`${errorsScope} d-flex flex-column justify-content-center ${className} w-100`}
      style={{position: 'relative'}}>
      {!team && (
        <div className='sep'>
          <div className='bar'></div>
        </div>
      )}
      {!team && (
        <div className='toggle-mode' onClick={() => setTeamMode(!teamMode)}>
          <i className='fa fa-circle text-white' />
        </div>
      )}

      {!team && (
        <div className='text-center'>
          {t(`onboarding.actions.${action}.title`, false) && (
            <h2 className='m-0 mb-3'>{t(`onboarding.actions.${action}.title`)}</h2>
          )}
          {t(`onboarding.actions.${action}.subtitle`, false) && (
            <p className='text-light col-md-10 col-lg-8 m-auto py-3 pb-4 pt-md-0'>
              {t(`onboarding.actions.${action}.subtitle`, {page, invitedBy})}
            </p>
          )}
          {t(`onboarding.actions.${action}.icon`, false) && (
            <img
              width=''
              className='img-fluid mt-4 mb-4 mb-md-0'
              src={t(`onboarding.actions.${action}.icon`)}
            />
          )}
        </div>
      )}

      {env.debug && loginLink && (
        <DebugBlock>
          <a href={loginLink}>Login Link</a>
        </DebugBlock>
      )}

      {action != 'swap' && (
        <div className='w-100'>
          <form onSubmit={submit} className='d-flex flex-column col-md-9 mx-auto'>
            {action == 'start' && (
              <div className='form-group'>
                <input
                  errkey='identifier'
                  onChange={(e) => setIdentifier(e.target.value)}
                  value={identifier}
                  className='form-control'
                  placeholder={t('common.email')}
                  type='email'
                  autoComplete='on'
                />
              </div>
            )}
            {action == 'signup' && (
              <div className='form-group'>
                <div className='mb-2 text-light'>{t('onboarding.labels.name', page)}</div>
                <input
                  errkey='name'
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  className='form-control'
                  placeholder={t('common.fullname')}
                  type='text'
                  autoComplete='on'
                />
                <div className='mt-3 mb-2 text-light'>
                  {t('onboarding.labels.relationship', page)}
                </div>
                <select
                  errkey='relationship'
                  name='relationship'
                  value={relationship}
                  onChange={(e) => setRelationship(e.target.value)}
                  className='form-control'>
                  <option value='' selected hidden></option>
                  {Profile.relationshipCases(t, page).map((r) => (
                    <option key={`relationships.option.${r}`} value={r}>
                      {t(`relationships.list.${r}.${page.defunct.gender || 'm'}`)}
                    </option>
                  ))}
                </select>
                <div className='mt-3 mb-2 text-light'>{t('onboarding.labels.location', page)}</div>
                <AddressForm className='mt-2' form={address} onChange={setAddress} />
                <div className='form-check mt-3'>
                  <input type='checkbox' className='form-check-input' id='newsletter' />
                  <label className='form-check-label text-light light' htmlFor='newsletter'>
                    {t('onboarding.labels.allowNewsletterContacts', page)}
                  </label>
                </div>
              </div>
            )}
            {action == 'start' && teamMode && (
              <div className='form-group'>
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  className='form-control'
                  placeholder={t('common.password')}
                  type='password'
                  autoComplete='current-password'
                />
              </div>
            )}

            <div className='text-center'>
              {action == 'start' && (
                <BtnSpin
                  isLoading={isLoading}
                  onClick={submit}
                  className='btn btn-primary w-100 login-btn'>
                  {t(`onboarding.user.cta.customize`)}
                </BtnSpin>
              )}
              {action == 'signup' && (
                <BtnSpin
                  isLoading={isLoading}
                  onClick={submit}
                  className='btn btn-primary w-100 signup-btn'>
                  {t(`common.login`)}
                </BtnSpin>
              )}
              {action == 'signup' && <TOS />}
              {action == 'start' && !team && (
                <div className='pt-2'>
                  <GDPR />
                </div>
              )}
            </div>
          </form>
        </div>
      )}

      <style jsx>
        {`
          .light {
            font-size: 10px;
            line-height: 11px;
            color: #9e9e9e;
          }

          img {
            width: 100px;
          }

          .sep {
            // background: red;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            text-align: center;
          }
          .sep .bar {
            border-top: 1px solid #eee;
            width: 200px;
            margin: auto;
          }

          .toggle-mode {
            position: fixed;
            top: 0;
          }

          @media (max-width: 767px) {
            // md down
            .sep {
              display: none;
            }

            .toggle-mode i {
              color: transparent !important;
            }
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(Login)
